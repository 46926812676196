<template>
    <div class="scrollbar-container">
        <div class="scrollbar-fill" :style="{ width: scrollWidth + '%' }"></div>
    </div>
</template>

<script>
export default {
    data() {
        return {
            scrollWidth: 0,
        };
    },
    mounted() {
        window.addEventListener('scroll', this.handleScroll);
    },
    beforeUnmount() {
        window.removeEventListener('scroll', this.handleScroll);
    },
    methods: {
        handleScroll() {
            const scrollTop = document.documentElement.scrollTop;
            const scrollHeight = document.documentElement.scrollHeight - document.documentElement.clientHeight;
            const scrollPercentage = (scrollTop / scrollHeight) * 100;
            this.scrollWidth = scrollPercentage;
        },
    },
};
</script>

<style lang="scss" scoped>
.scrollbar-container {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 5px;
    /* Höhe der Scrollbar */
    background-color: #4e4e4e;
    /* Hintergrundfarbe der Scrollbar */
    z-index: 1000;
    /* Damit die Scrollbar immer oben bleibt */
}

.scrollbar-fill {
    height: 100%;
    width: 0;
    background-color: #712cf9;
    transition: width 0.1s linear;
}

body.light {
    .scrollbar-container { 
        background-color: #d6d6d6;
    }
}
</style>