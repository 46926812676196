<template>
  <div>
    <CustomScrollbar v-if="isAuthenticated" />
    <MainNav v-if="isAuthenticated" />
    <div v-if="isAuthenticated" class="container top-row">
      <div id="logo">
        <router-link class="not_bubbled" to="/">Andreas S. König</router-link>
      </div>
      <DarkModeToggle />
    </div>

    <div class="content main">
      <router-view v-slot="{ Component }" class="inner">
        <transition
          enter-active-class="animate__animated animate__fadeIn"
          leave-active-class="animate__animated animate__fadeOut"
          mode="out-in"
        >
          <component :key="$route.path" :is="Component"></component>
        </transition>
      </router-view>
      <div v-if="isAuthenticated" class="spacer"></div>
    </div>

    <Footer v-if="isAuthenticated" />
  </div>
</template>

<script setup>
import { ref, onMounted, onBeforeUnmount } from "vue";
import axios from "axios";
import { useRouter } from "vue-router";
import { EventBus } from "@/eventBus.js";

import DarkModeToggle from "@/components/DarkModeToggle.vue";
import Footer from "@/components/Footer.vue";
import MainNav from "@/components/MainNav.vue";
import CustomScrollbar from "@/components/CustomScrollbar.vue";

const router = useRouter();

// Reaktiver State für Auth
const isAuthenticated = ref(false);

// Funktion zur Token-Prüfung
async function checkAuthentication() {
  const token = localStorage.getItem("token");
  if (!token) {
    isAuthenticated.value = false;
    router.push("/login");
    return;
  }

  try {
    await axios.get("https://api.andreas-koenig.com/api/users/me", {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    isAuthenticated.value = true;
  } catch (error) {
    isAuthenticated.value = false;
    router.push("/login");
  }
}

// Beim Komponentenmounden Auth prüfen und EventBus-Listener registrieren
onMounted(() => {
  checkAuthentication();

  // Wenn "recheck-auth" ausgelöst wird, erneut Token validieren
  EventBus.on("recheck-auth", checkAuthentication);
});

// Sauber entfernen, wenn Komponente vom DOM geht
onBeforeUnmount(() => {
  EventBus.off("recheck-auth", checkAuthentication);
});
</script>

<style lang="scss">
//@import '@/../mdb/scss/index.free.scss';
@import "~@/assets/scss/variables";
@import "~@/assets/scss/inital";
@import "~@/assets/scss/dark";
@import "~@/assets/scss/mixins";

html,
body {
  height: 100%;
}

/* Verstecke die Standard-Scrollbar für Webkit-Browser (Chrome, Safari) */
body::-webkit-scrollbar {
  display: none;
}

/* Verstecke die Standard-Scrollbar für Firefox */
body {
  scrollbar-width: none;
  /* Firefox */
}

html,
#app {
  position: relative;
}

#app {
  /* Verstecke die Standard-Scrollbar */

  scrollbar-width: none;
  /* Für Firefox */


  &::-webkit-scrollbar {
    display: none;
    /* Für Webkit-Browser (Chrome, Safari) */
  }
}

#logo {
  z-index: 9999;
  opacity: 0.7;
  font-weight: 800;
  text-transform: uppercase;

  &:hover {
    opacity: 1;

    a {
      color: #7532fa;
    }
  }
}

.top-row {
  z-index: 999;
  display: flex;
  align-items: center;
  justify-content: space-between;
  position: absolute;
  top: 1em;
  left: 0em;
  right: 0em;

}

h1,
h3 {
  color: #1d1d1d;
}

h2 {
  color: #712cf9
}

h1,
h2,
h3 {
  font-style: normal;
  font-weight: 800;
  line-height: 100%;
}

h2 {
  line-height: 1.25em;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  margin-bottom: 0.75em;
}

h1 {
  //font-weight: 300;
  font-size: clamp(1.75rem, 5vw, 3rem);
  text-transform: uppercase;
  margin-bottom: 0.25em;
}

.spacer {
  padding-top: 6em;
}

h2 {
  font-weight: 600;
  font-size: clamp(1.5rem, 3.5vw, 1.75rem);
}

h3 {
  font-weight: 400;
  font-size: clamp(1.25rem, 2.5vw, 1.5rem);
}

h4 {
  font-weight: 400;
  font-size: clamp(1.15rem, 2vw, 1.25rem);
  margin-bottom: 0.75em;
}

h5,
h6 {
  font-size: 1rem;
  font-weight: 600;
  margin-bottom: 0.75em;
  font-size: clamp(1rem, 2vw, 1.15rem);
}

p,
span {
  font-size: clamp(1rem, 2vw, 1.15rem);

  a {
    font-size: clamp(1rem, 2vw, 1.15rem);
  }
}

body {
  padding: 0;
  margin: 0;

}

* {
  font-family: 'Montserrat', sans-serif;

}

a {
  color: black;
  text-decoration: none;
}

.no-padding {
  padding: 0 !important;
}

.markdown-body {
  *:last-child {
    margin-bottom: 0 !important;
  }
}

.markdown-body {
  max-width: 800px;
  margin: 0 auto;
}

* {
  &:not(.markdown-body) {
    >ul {
      list-style: none;
      padding: 0;
    }
  }
}


.content {
  padding: 0;
  padding-top: 7.5rem;
  margin: 0;
  display: block;
  min-width: 100%;
  min-height: 100%;
  min-height: 100vh;
  z-index: 2;
  position: relative;
  background-color: white;
  //padding-bottom: 2em;
  box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);

  .inner {
    padding-bottom: 6em;
  }
}

.content-inner {
  width: 80%;
  max-width: 1280px;
  // margin: 0 auto;
}

.centerXY {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
}

.centerX {
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
}

.rounded-wrapper {
  will-change: height;
  width: 100%;
  position: relative;
  height: 10vh;
  overflow: hidden;
  z-index: 2;
}

.rounded-div {
  width: 150%;
  content: "";
  display: block;
  position: absolute;
  background: white;
  height: 750%;
  left: 50%;
  border-radius: 50%;
  transform: translate(-50%, -86.666%);
  z-index: 1;
}

@media (max-width: 767px) {

  .container {
    padding: 0 1.5rem;
  }

  .rounded-wrapper {
    .rounded-div {
      height: 500%
    }
  }

  .media-grid {
    .flex {
      .f-item {
        &:not(:first-of-type) {
          padding-top: 2rem;
        }
      }
    }
  }

}

@media (max-width: 991px) {

  .container-sm,
  .container {
    max-width: inherit !important;
    padding: 0 4vw !important;
  }
}
</style>