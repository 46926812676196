<template>
  <div class="rounded-wrapper">
    <div class="rounded-div"></div>
  </div>
  <div class="footer_spacer"></div>
  <footer>
    <div class="outer">
      <div class="marquee-wrapper">
        <Vue3Marquee id="marquee-slider-loop" :speed="16000" :width="3300">
          <div class="marquee-heading">Let's work together!</div>
        </Vue3Marquee>
      </div>
      <div class="container">
        <div class="content-wrapper">

          <div class="text-wrapper">
            <div class="" style="position: relative; width: 100%;">
              <kinesis-container class="">
                <kinesis-element :strength="-15" type="translate">
                  <a class="mail" href="mailto:hi@andreas-koenig.com">
                    <LottieAnimation :animationData="WavingHand" :height="48" :width="48" />
                    <span>hi@andreas-koenig.com</span>
                  </a>
                </kinesis-element>
              </kinesis-container>
            </div>
          </div>

          <div class="social-wrapper">
            <kinesis-container class="footer_kienesis">
              <kinesis-element :strength="-15" type="translate">
                <a href="https://www.linkedin.com/in/andreas-k%C3%B6nig-5375a614b/" target="_blank"><i
                    class="fab fa-linkedin"></i></a>
              </kinesis-element>
            </kinesis-container>
            <!--  <kinesis-container class="footer_kienesis">
                                <kinesis-element :strength="-15" type="translate">
                                    <a href="https://www.xing.com/profile/Andreas_Koenig195/cv" target="_blank"><i
                                            class="fab fa-xing-square"></i></a>
                                </kinesis-element>
                            </kinesis-container>-->
            <kinesis-container class="footer_kienesis">
              <kinesis-element :strength="-15" type="translate">
                <a href="https://www.facebook.com/andreas.koene" target="_blank"><i class="fab fa-facebook"></i></a>
              </kinesis-element>
            </kinesis-container>
            <kinesis-container class="footer_kienesis">
              <kinesis-element :strength="-15" type="translate">
                <a href="https://www.instagram.com/_koene/" target="_blank"><i class="fab fa-instagram"></i></a>
              </kinesis-element>
            </kinesis-container>
            <kinesis-container class="footer_kienesis">
              <kinesis-element :strength="-15" type="translate">
                <a href="https://soundcloud.com/andreaskoenig" target="_blank"><i class="fab fa-soundcloud"></i></a>
              </kinesis-element>
            </kinesis-container>
            <kinesis-container class="footer_kienesis">
              <kinesis-element :strength="-15" type="translate">
                <a href="https://open.spotify.com/user/andikoenig91" target="_blank">
                  <i class="fab fa-spotify"></i>
                </a>
              </kinesis-element>
            </kinesis-container>
            <kinesis-container class="footer_kienesis">
              <kinesis-element :strength="-15" type="translate">
                <a href="https://www.strava.com/athletes/105775020" target="_blank">
                  <i class="fab fa-strava"></i>
                </a>
              </kinesis-element>
            </kinesis-container>
          </div>

          <div class="bottom-row">
            <div class="song-wrapper ">
              <kinesis-container class="footer_kienesis">
                <kinesis-element :strength="-15" type="translate">
                  <SpotifySong />
                </kinesis-element>
              </kinesis-container>
            </div>

            <div class="legal-wrapper">
              <kinesis-container class="footer_kienesis">
                <kinesis-element :strength="-15" type="translate">
                  <router-link to="/imprint">Impressum</router-link>
                </kinesis-element>
              </kinesis-container>
              <kinesis-container class="footer_kienesis">
                <kinesis-element :strength="-15" type="translate">
                  <router-link to="/data-protection">Datenschutzerklärung</router-link>
                </kinesis-element>
              </kinesis-container>
            </div>



          </div>
        </div>
      </div>
    </div>
  </footer>
</template>

<script>
import SpotifySong from './SpotifySong.vue';
//import { VueMarqueeSlider } from 'vue3-marquee-slider';
import { Vue3Marquee } from 'vue3-marquee';
import WavingHand from '../assets/lotties/waving_hand.json';
//import { Vue3Lottie } from 'vue3-lottie';
//import 'vue3-lottie/dist/style.css';
import { KinesisContainer, KinesisElement } from "vue-kinesis";

export default {
  name: 'Footer',
  setup() {
  },
  components: {
    SpotifySong,
    //VueMarqueeSlider,
    Vue3Marquee,
    //Vue3Lottie
    KinesisContainer,
    KinesisElement
  },
  data() {
    return {
      data: {},
      WavingHand
    };
  },
  computed: {

  },
  mounted() {
    this.setContentOpacity();
    window.addEventListener('scroll', this.setContentOpacity, true);
  },
  methods: {
    setContentOpacity() {
      let spacer = document.querySelector('.footer_spacer');
      let footer = document.querySelector('footer .outer');
      let rect = spacer.getBoundingClientRect();
      let con = footer.querySelector(".container");
      let rounded_wrapper = document.querySelector('.rounded-wrapper');
      let footer_content = document.querySelector('footer .container');
      let maqruee_slider = document.querySelector('footer .marquee-wrapper');
      const footer_height = rect.height;

      footer.classList.remove("animated");
      if (rect.top < window.innerHeight && rect.bottom > 0) {
        footer.classList.add("animated");
        var set = (window.innerHeight - rect.bottom + rect.height) / rect.height;
        footer.style.opacity = set;

        var rounded_wrapper_height = 100 - set * 100 + "px";
        rounded_wrapper.style.height = rounded_wrapper_height;

        var footer_bottom_pos = 100 - set * 100 + "px";
        footer_content.style.bottom = footer_bottom_pos;

        var marquee_bottom_pos = footer_height / 2 - set * 50 + "px";
        maqruee_slider.style.bottom = marquee_bottom_pos;
        maqruee_slider.style.opacity = set / 5 * 3;


        if (rect.top + (rect.height / 2) < window.innerHeight) {
          var set_2 = (window.innerHeight - rect.bottom + (rect.height / 2)) / rect.height * 2;
          con.style.opacity = set_2;
        } else {
          con.style.opacity = 0;
        }

      } else {
        footer.classList.remove("animated");
        footer.style.opacity = 0;

      }
    }

  },
  watch: {

  }
}
</script>

<style lang="scss" scoped>
@import "@/assets/scss/variables";

footer {
  position: fixed;
  bottom: 0;
  left: 0;
  height: 100vh;
  width: 100vw;
  background-color: #090909;
  z-index: 1;

  .footer_kienesis {
    width: 48px;
    float: left;
  }

  * {
    color: white !important;
  }

  a {
    color: white !important;
    vertical-align: middle;

    &.spotify {
      margin-right: 0.5em !important;
    }

    &.mail {
      font-size: 1.5em;
      font-weight: 400;
      display: inline-flex;
      align-items: center;
      justify-content: space-between;
      align-content: center;
      flex-wrap: nowrap;
      flex-direction: row;
      padding-right: 1em;
    }
  }

  li {
    display: inline-block;
    font-size: 1em;

    &:not(:last-of-type) {
      margin-right: 1.5em;
    }
  }

  ul {
    margin: 0;
  }

  .container {
    width: 100%;
    height: $var-footer-height;
    position: absolute;
    bottom: 0;
    left: 50%;
    transform: translate(-50%, 0);
  }

  .content-wrapper {
    position: relative;
    height: 100%;
    width: 100%;
  }

  .lottie-animation-container {
    margin-right: 0.5em;
  }

  .legal-wrapper,
  .social-wrapper,
  .text-wrapper {
    text-align: center;
  }

  .text-wrapper {
    span {
      font-size: 1em;
      width: 100%;
    }
  }

  .legal-wrapper,
  .song-wrapper {
    .footer_kienesis {
      width: inherit;
      float: left;

      &:not(:last-of-type) {
        margin-right: 1.5em;
      }
    }
  }



  .social-wrapper {
    display: flex;
    justify-content: space-evenly;
    flex-wrap: wrap;
    flex-direction: row;
    font-size: 2em;
    position: absolute;
    bottom: 20%;
    left: 50%;
    min-width: 350px;
    width: 40vw;
    transform: translateX(-50%);
  }

  .marquee-wrapper {
    will-change: bottom;

    .marquee-heading {
      font-weight: 700;
      padding: 0;
      margin: 0;
      padding-right: 0.5em;
    }
  }
}

.footer_spacer {
  height: $var-footer-height;
  width: 100%;
  opacity: 0;
}

footer .outer {
  background-size: 1600% 1600%;
  width: 100%;
  height: 100%;

  &.animated {
    background: linear-gradient(45deg, #37167a, #37167a, #712cf9, #503b7a, #5a24c7, #5a24c7);
    background-size: 1000% 1000%;
    animation: gradient 8s ease infinite alternate;
  }
}

@keyframes gradient {
  0% {
    background-position: 0% 50%;
  }

  100% {
    background-position: 50% 100%;
  }
}

@media (max-width: 1199px) {
  .bottom-row {
    .song-wrapper {}
  }

}


@media (max-width: 1027px) {
  footer {
    width: 100%;
    min-width: 100%;

    .bottom-row {
      position: absolute;
      width: 100%;
      min-width: 100%;
      display: flex;
      align-items: left;
      align-content: center;
      flex-wrap: nowrap;
      flex-direction: column;
      bottom: 2em;

      &>div {
        width: 100%;
        position: relative;
        padding-bottom: 1em;
        text-align: center;
      }


      .legal-wrapper {
        display: flex;
        justify-content: center;

        >div {
          width: initial;
        }
      }

      .song-wrapper {
        padding-bottom: 2em;
      }
    }

    .social-wrapper {
      font-size: clamp(3vw, 1.5em, 5vw);
      bottom: 30%;

      .footer_kienesis {
        width: 48px;
        float: left;
      }
    }

    .text-wrapper {
      position: absolute;
      bottom: calc($var-footer-height / 2.2);
      left: 0;
      width: 100%;

      span {
        font-size: clamp(4vw, 1.5em, 4vw);
      }
    }

    .marquee-wrapper {
      opacity: 0.6;
      z-index: 1;
      position: absolute;
      left: 0;
      bottom: calc($var-footer-height / 2 - 5em) !important;
      height: $var-footer-height;
      transform: translateY(45%);
      width: 100%;
      overflow: hidden;
      pointer-events: none;

      .marquee-heading {
        width: 200%;
        font-size: clamp(4vw, 12rem, 30vw);
        text-transform: uppercase;
        padding-right: 0.5em;
      }
    }
  }
}


@media (min-width: 1028px) {
  .bottom-row {
    position: absolute;
    bottom: 2em;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    align-content: center;
    flex-wrap: nowrap;
    flex-direction: row;
  }

  .marquee-wrapper {
    opacity: 0.6;
    z-index: 1;
    position: absolute;
    left: 0;
    bottom: calc($var-footer-height / 2 - 3em);
    height: calc($var-footer-height + 4rem);
    transform: translateY(50%);
    width: 100%;
    overflow: hidden;
    pointer-events: none;

    .marquee-heading {
      width: 200%;
      font-size: clamp(5vw, 15rem, 40vw);
      text-transform: uppercase;
    }
  }

  .text-wrapper {
    position: absolute;
    bottom: calc($var-footer-height / 3);
    width: 100%;
  }

  .legal-wrapper,
  .social-wrapper,
  .text-wrapper {
    z-index: 10;
    pointer-events: all;
  }
}


@media (max-width: 399px) {
  footer {
    min-width: 100%;
  }

  .song-wrapper {
    a {
      align-items: center;
    }
  }

  .social-wrapper {
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    flex-direction: row;
    font-size: 1.5em !important;
    align-items: center;
    bottom: calc($var-footer-height / 3 - 1em) !important;
  }

  .text-wrapper {
    bottom: calc($var-footer-height / 2 + 1em) !important;
  }

}
</style>