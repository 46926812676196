<template>
    <div class="mode-toggle" @click="toggleDark()">
        <div class="toggle">
            <div id="dark-mode" type="checkbox">
                <i class="fas fa-moon"></i>
                <i class="fas fa-sun"></i>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'DarkModeToggle',
    components: {
    },
    data() {
        return {
        };
    },
    computed: {

    },
    mounted() {
    },
    methods: {

    },
};
</script>

<script setup>
import { useDark, useToggle } from "@vueuse/core";
const isDark = useDark({
    selector: "body", //element to add attribute to
    attribute: "class", // attribute name
    valueLight: "light",
    valueDark: 'dark'
});
const toggleDark = useToggle(isDark);
</script>
  
<style lang="scss"> 

.mode-toggle {
     z-index: 9999;
     width: 3.5em;
     height: 2em;
     background-color: #d4d4d4;
     border-radius: 1em;
     position: relative;
    // top: 1em;
    // right: 1em;
     cursor: pointer;
     transition: all .5s ease;
     overflow: hidden;

     .toggle {
         transition: all .5s ease;
         height: 2em;
         width: 2em;
         background-color: #f3f3f3;
         border-radius: 1em;
         position: absolute;
         left: 0;
         top: 0;
         bottom: 0;
         overflow: hidden;
        &:hover {
            background-color: #712cf9 !important;
            box-shadow: 0 0 16px 0 rgba(0, 0, 0, 0.5);
            .fa-sun {
                &::before {
                    color: rgba(white, 0.7) !important;
                }
            }
        }
         #dark-mode {
             .fa-moon {
                opacity: 0;
                 //display: none !important;
             }

             .fa-moon,
             .fa-sun {
                 position: absolute;
                 top: 50%;
                 left: 50%;                
                 &::before {
                    transition: color .5s ease;
                     color: rgba(black, 0.7);
                 }
             }
             .fa-moon {
                transition: transform .5s ease-out;
                transform: rotate(-75deg) translate(-50%, -50%) ;
             }
             .fa-sun {
                transition: transform .5s ease-out;
                transform: rotate(0) translate(-50%, -50%) ;
             }
         }
     }
 }

 body {
     &.dark {
         .mode-toggle {
             background-color: #1e1e1e;

             .toggle {
                 //left: inherit;
                 //right: 0;
                 transform: translateX(1.5em);
                 background-color: black;

                 #dark-mode {
                     .fa-sun {
                         //display: none !important;
                         opacity: 0;
                         transform: translate(-50%, -50%) rotate(90deg);
                     }

                     .fa-moon {
                         //display: inherit !important;
                         opacity: 1;
                         transform: translate(-50%, -50%) rotate(-0);
                     }

                     .fa-moon,
                     .fa-sun {
                         &::before {
                             color: rgba(white, 0.7);
                         }

                     }
                 }
             }
         }
     }
 }
</style>