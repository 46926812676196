import { createRouter, createWebHistory } from "vue-router";


function lazyLoad(view) {
  return () => import(`@/views/${view}.vue`)
}

const routes = [
  {
    path: "/",
    name: "home",
    component: lazyLoad("Home"),
    meta: { requiresAuth: true },
  },
  {
    path: "/about",
    name: "about",
    component: lazyLoad("About"),
    meta: { requiresAuth: true },
  },
  {
    path: "/projects",
    //alias: "/",
    children: [
      {
        path: "",
        name: 'projects',
        component: lazyLoad("Projects"),
        meta: { requiresAuth: true },
        props: true
      },
      {
        path: "/projects/:slug",
        name: 'project',
        component: lazyLoad("Project"),
        meta: { requiresAuth: true },
        props: true
      },
      {
        path: "/projects/category/:name",
        name: 'category',
        component: lazyLoad("Category"),
        meta: { requiresAuth: true },
        props: true
      }
    ]
  },
  {
    path: "/imprint",
    name: "imprint",
    component: lazyLoad("Imprint"),
    meta: { requiresAuth: true },
  }, {
    path: "/data-protection",
    name: "data-protection",
    component: lazyLoad("DataProtection"),
    meta: { requiresAuth: true },
  },
  {
    path: "/:pathMatch(.*)*",
    name: "not-found",
    component: lazyLoad("NotFound"),
    meta: { requiresAuth: false },
  },
  {
    path: "/login",
    name: "login",
    component: lazyLoad("Login"),
    meta: { requiresAuth: false },
  },

];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
  scrollBehavior() {
    return { top: 0 };
  },
});

// Globale Navigation-Guard
router.beforeEach((to, from, next) => {
  const token = localStorage.getItem("token");
  if (!token && to.name !== "login") {
    next({ name: "login" });
  } else {
    next();
  }
});

export default router;