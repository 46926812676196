import { Buffer } from "buffer";
const clientID = "0e186d6ccf7f428a84185843774a33ba";//process.env.spotifyClientID
const clientSecret = "948c2f185b8f4e58b68f7b6c94f5dd20";//process.env.spotifyClientSecret
const refreshToken = "AQCmUVV4Wxf5duovBBcKDIho7tv4y0_Rnzc7hlJ3nGqPfHEgL2zExoKAtkdbQaqGAkAL5nHRY6sXY-q6PFyqW-9pYY8XM0BV8nE9FWWBAqHYPHDxAJ0_B5l5MYBvVrGg3fE";//process.env.spotifyRefreshToken

const basic = Buffer.from(`${clientID}:${clientSecret}`).toString('base64')
const TOKEN_ENDPOINT = 'https://accounts.spotify.com/api/token'

// This gets the access token from Spotify to connect to the API using provided refresh token.
const getAccessToken = async () => {
  const response = await fetch(TOKEN_ENDPOINT, {
    method: 'POST',
    headers: {
      Authorization: `Basic ${basic}`,
      'Content-Type': 'application/x-www-form-urlencoded'
    },
    body: new URLSearchParams({
      grant_type: 'refresh_token',
      refresh_token: refreshToken
    }).toString()
  })
  return response.json()
}

// Get the current now playing track of given user access token.
const NOW_PLAYING_ENDPOINT = 'https://api.spotify.com/v1/me/player/currently-playing'
export const getNowPlaying = async () => {
  const { access_token: accessToken } = await getAccessToken()
  return fetch(NOW_PLAYING_ENDPOINT, {
    headers: {
      Authorization: `Bearer ${accessToken}`
    }
  })
}