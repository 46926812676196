<template>
    <a v-if="this.spotify.is_playing != null" :href="this.spotify.href" target="_blank">

        <div class="soundbar">
            <div class="stroke"></div>
            <div class="stroke"></div>
            <div class="stroke"></div>
        </div>

        {{ currentTrackStr }} &nbsp; <strong>{{ this.spotify.track }}</strong> &nbsp; by &nbsp; <strong>{{
            this.spotify.artist }}</strong>
    </a>
    <a target="_blank" href="https://open.spotify.com/user/andikoenig91" v-else>
        <div class="soundbar">
            <div class="stroke"></div>
            <div class="stroke"></div>
            <div class="stroke"></div>
        </div>
        {{ currentTrackStr }}
    </a>
</template>
  
<script>
import { getNowPlaying } from '../spotify/api'
export default {
    data() {
        return {
            spotify: { is_playing: null, href: null, track: null, artist: null },
            currentTrackStr: 'Nothing playing right now.'
        }
    },
    watch: {
        '$route.path'() {
            this.currentTrack()
        }
    },
    beforeMount() {
        this.currentTrack()
    },
    methods: {
        truncateText(text, length) {
            if (text.length <= length) {
                return text;
            }

            return text.substr(0, length) + '\u2026'
        },
        async currentTrack() {
            try {
                const response = await getNowPlaying()
                if (response.status === 200) {
                    const { item, is_playing: np } = await response.json()
                    //this.currentTrackStr = `${np ? 'Now playing:' : 'Last played:'} ${item.name} by ${item.artists.map(artist => artist.name).join(', ')}.`
                    this.currentTrackStr = `${np ? 'Now playing:' : 'Last played:'}`;
                    this.spotify.href = item.external_urls.spotify;
                    this.spotify.track = this.truncateText(item.name, 30);
                    this.spotify.artist = this.truncateText(item.artists.map(artist => artist.name).join(', '), 30);
                    this.spotify.is_playing = np;
                    //console.log(this.spotify);
                }
            } catch (e) {
                console.log('Couldn\'t fetch data from Spotify :(');
                //this.currentTrackStr = 'Couldn\'t fetch data :('
            }
        }
    }
}
</script>
<style scoped lang="scss">
a {
    display: inline-flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;
    font-size: 1em;
    margin-left: 0;
}


.soundbar {
    height: 16px;
    display: flex;
    align-items: center;
    padding: initial !important;
    margin-right: 1em;

    .stroke {
        padding: initial !important;

        display: block;
        position: relative;
        background-color: #f1f1f1;
        height: 100%;
        width: 2px;
        border-radius: 1px;
        margin: 0 2px;
        animation: animSound 1.2s infinite linear;

        &:nth-child(1) {
            animation-delay: 0s;
        }

        &:nth-child(2) {
            animation-delay: 0.6s;
        }

        &:nth-child(3) {
            animation-delay: 0.9s;
        }
    }
}

@keyframes animSound {
    50% {
        height: 20%;
    }

    100% {
        height: 100%;
    }
}
</style>
  