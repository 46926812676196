<template>
    <div class="nav-wrapper">
        <div class="sticky-box">
            <nav>
                <ul>
                   <li><router-link to="/">Willkommen</router-link></li>
                    <li><router-link to="/projects">Projekte</router-link></li>
                    <li><router-link to="/about">Über mich</router-link></li>
                </ul>
            </nav>
        </div>
    </div>
</template>


<style lang="scss">
@import "@/assets/scss/variables";

a {
    &.button {
        padding: 0.75em 1.5em;
        border: 2px solid #712cf9;
        border-radius: 48px;
        display: inline-block;
    }

    &:not(.not_bubbled) {
        position: relative;
        z-index: 2;

        &:hover {
            color: black;
            opacity: 1;
        }

        //&.router-link-active,
        &.router-link-exact-active {
            color: white;

            &:before {
                transform: scale(1.0);
                opacity: 1;
            }
        }


        &:before {
            content: "";
            position: absolute;
            top: -0.5em;
            right: -0.5em;
            left: -0.5em;
            bottom: -0.5em;
            z-index: -1;
            border-radius: 100px;
            background-color: #712cf9;
            transition: all .25s ease-out;
            transform: scale(0);
            opacity: 0;
        }

        &:hover {
            &:not(.router-link-exact-active) {
                &:before {
                    opacity: 0.25;
                    transform: scale(1.0);
                }
            }
        }
    }
}

.sticky-box {
    z-index: 99;
    position: sticky;
    text-align: center;
    //bottom: 2em;
    top: calc(100% - 3.5em);
    width: 100%;
}

.nav-wrapper {
    width: 100%;
    position: absolute;
    top: 0;
    bottom: calc($var-footer-height + 2em);
}

nav {
    display: inline-block;
    position: relative;
    padding: 0.25em 1em;
    border-radius: 100px;
    background-color: #712cf9; // #1e1e1e;
    transform: translateY(-50%);

    &.back {
        display: none;
        margin-right: -4em;
        width: 8em;
    }

    a {
        color: white;
        font-weight: 600;
    }



    ul {
        margin-top: 1rem;
        margin-bottom: 1rem;

        li {
            display: inline-block;
            margin: 0 10px;

            a {
                position: relative;
                z-index: 2;

                &:hover {
                    color: white !important;
                }

                &:before {
                    content: "";
                    position: absolute;
                    top: -0.5em;
                    right: -0.5em;
                    left: -0.5em;
                    bottom: -0.5em;
                    z-index: -1;
                    border-radius: 100px;
                    background-color: white !important;
                    transition: all .25s ease-out;
                    transform: scale(0);
                    opacity: 0;
                }

                &.router-link-active,
                &.router-link-exact-active {
                    color: black !important;

                    &:before {
                        transform: scale(1.0);
                        opacity: 1;
                    }
                }

                &:hover {
                    &:not(.router-link-exact-active) {
                        &:before {
                            opacity: 0.25;
                            transform: scale(1.0);
                        }
                    }
                }
            }
        }
    }
}
</style>