import { createApp } from "vue";
import App from "./App.vue";
import router from "./router";
import axios from "axios";
import Vue3Lottie from 'vue3-lottie';
import 'vue3-lottie/dist/style.css';
import MasonryWall from '@yeger/vue-masonry-wall';
import VueKinesis from "vue-kinesis";


axios.defaults.baseURL = "https://api.andreas-koenig.com/api";

const app = createApp(App);

// Prüfen, ob bereits ein Token im Local Storage liegt
const token = localStorage.getItem('token');
if (token) {
  axios.defaults.headers.common['Authorization'] = `Bearer ${token}`;
}

app.config.globalProperties.$http = axios;

app.use(router);
app.use(Vue3Lottie, { name: 'LottieAnimation' });
app.use(MasonryWall);
app.use(VueKinesis);
app.mount("#app");